<template>
    <div class="text-sm font-normal tag-input" :class="{ 'with-count': showCount }">
      <input
        v-model="newTag"
        type="text"
        :list="id"
        autocomplete="off"
        @keydown.enter.prevent="handleEnterKey"
        @keydown.prevent.tab="handleTagInput(newTag)"
        @keydown.delete="newTag.length || removeTag(tags.length - 1)"
        :style="{ 'padding-left': `${paddingLeft}px` }"
        class="text-sm border border-gray-300 rounded-md outline-none text-primary"
        @input="filterOptions"
      />
      <datalist v-if="filteredOptions.length" :id="id">
        <option
          v-for="option in filteredOptions"
          :key="option"
          :value="option"
        >
          {{ option }}
        </option>
      </datalist>

      <ul class="!max-w-full tags" ref="tagsUl">
        <li
          v-for="(tag, index) in tags"
          :key="tag"
          class="inline-flex items-center gap-1 px-2 py-0.5 text-xs font-medium text-center bg-blue-800 rounded-lg tag bg-opacity-10 text-indigo-950"
          :class="{ duplicate: tag === duplicate }"
        >
          {{ tag }}
          <GenericButton class="delete !p-0" @click="removeTag(index)">
            <DeleteIcon />
          </GenericButton>
        </li>
      </ul>
    </div>
  </template>



  <script>
 import { ref, watch, nextTick, onMounted } from 'vue';
import DeleteIcon from '@/Icons/delete.vue';
import GenericButton from './GenericButton.vue';

export default {
  components: {
    DeleteIcon,
    GenericButton
  },
  props: {
    name: { type: String, default: '' },
    modelValue: { type: Array, default: () => [] },
    options: { type: [Array, Boolean], default: false },
    allowCustom: { type: Boolean, default: true },
    showCount: { type: Boolean, default: false }
  },
  setup(props, { emit }) {
    const tags = ref(props.modelValue);
    const newTag = ref('');
    const id = Math.random().toString(36).substring(7);
    const filteredOptions = ref([]);

    // Function to filter options based on input
    const filterOptions = () => {
      if (!props.options || props.options === true) return;
      const query = newTag.value.toLowerCase();
      filteredOptions.value = props.options.filter(option =>
        option.toLowerCase().includes(query) && !tags.value.includes(option)
      );
    };

    // Function to add a tag (custom or from options)
    const handleTagInput = (tag) => {
      if (!tag) return; // Prevent empty tag

      // Only allow predefined tags when allowCustom is false
      if (!props.allowCustom && !props.options.includes(tag)) return;

      if (tags.value.includes(tag)) {
        handleDuplicate(tag);
        return;
      }

      tags.value.push(tag);
      newTag.value = ''; // Reset newTag
      filterOptions(); // Re-filter options after adding a tag
    };

    // Function to handle Enter key press
    const handleEnterKey = () => {
      handleTagInput(newTag.value.trim());
    };

    // Function to remove a tag
    const removeTag = (index) => {
      tags.value.splice(index, 1);
    };

    // Handling duplicates
    const duplicate = ref(null);
    const handleDuplicate = (tag) => {
      duplicate.value = tag;
      setTimeout(() => (duplicate.value = null), 1000);
      newTag.value = '';
    };

    // Positioning and handling tag change
    const paddingLeft = ref(10);
    const tagsUl = ref(null);

    const onTagsChange = () => {
      const extraCushion = 15;
      paddingLeft.value = tagsUl.value.clientWidth + extraCushion;
      tagsUl.value.scrollTo(tagsUl.value.scrollWidth, 0);
      emit('update:modelValue', tags.value);
    };

    watch(tags, () => nextTick(onTagsChange), { deep: true });
    onMounted(onTagsChange);

    // Initialize filtered options
    watch(() => props.options, () => {
      if (props.options) {
        filteredOptions.value = props.options.filter(option => !tags.value.includes(option));
      }
    });

    return {
      tags,
      newTag,
      handleTagInput,
      handleEnterKey,
      removeTag,
      paddingLeft,
      tagsUl,
      filteredOptions,
      id,
      duplicate,
      filterOptions
    };
  }
};

  </script>
<style scoped>
.tag-input {
  position: relative;
}
.input-container {
  position: relative;
}
input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0px 12px 24px -4px #919EAB1F;
  z-index: 1000;
}
.dropdown-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.dropdown-item {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}
.dropdown-item:hover {
  background-color: #f0f0f0;
}
ul {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 7px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  max-width: 75%;
  overflow-x: auto;
}
.tag {
  white-space: nowrap;
  transition: 0.1s ease background;
}
.delete {
  color: white;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;
}
@keyframes shake {
  10%, 90% {
    transform: scale(0.9) translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: scale(0.9) translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: scale(0.9) translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: scale(0.9) translate3d(4px, 0, 0);
  }
}
.tag.duplicate {
  background: rgb(235, 27, 27);
  animation: shake 1s;
}
.count {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  display: block;
  font-size: 0.8rem;
  white-space: nowrap;
}
.count span {
  background: #eee;
  padding: 2px;
  border-radius: 2px;
}
.with-count input {
  padding-right: 60px;
}
.with-count ul {
  max-width: 60%;
}
</style>
